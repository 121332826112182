
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Side-nav {
  position: fixed;
  left: -220px; /* Position them outside of the screen */
  min-width: 230px;
  height: 400px;
  transition: 0.3s; /* Add transition on hover */
  padding: 5px 0px; /* 15px padding */
  text-decoration: none; /* Remove underline */
  color: var(--text); /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  border-right: solid;
  border-color: rgb(123, 121, 121);
  background-color: var(--bg);
  overflow: scroll;
}
.Side-nav:hover {
  left: 0px;
}

.Side-nav a {
  border: none;
}

@media screen and (min-width:1200px) {
  .Side-nav {
    left: 0px;
    top: 0;
    height: 100vh;
  }
}
.shadow {
  box-shadow:
      0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075),
      0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075),
      0 16px 16px hsl(0deg 0% 0% / 0.075)
    ;
}

details {
  border: none;
}

.h2-nonopen::before{
  position: absolute;
  content:"+";
  font-size: 1.3em;
  margin-left: -20px;
  margin-top: -5px;
  color: #d3d7dc;
}

.h2-open::before{
  position: absolute;
  content:"-";
  font-size: 1.3em;
  margin-left: -20px;
  margin-top: -5px;
  color: #d3d7dc;
}

.h1-clicked{
  display: block;
  max-width: 180px;
  padding-left: 20px;
}

.h1-nonclick{
  display: none;
}

